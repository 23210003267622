import './SubmissionEditionPanel.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormCheckboxField,
  FormContext,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextAreaField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import { ISelectFieldOption } from '@novo-electronique/react-forms/dist/FormSelectFieldOptions';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';
import { useFormik } from 'formik';
import i18n from 'i18next';
import * as Yup from 'yup';

import { Language } from '@common/constants/language';
import { SubmissionStatus } from '@common/constants/submission-status';
import { SubmissionType } from '@common/constants/submission-type';
import { IPayment } from '@common/models/payment';
import { ISubmission } from '@common/models/submission';
import { getEditionValidationSchemaFromType, getVersion } from '@common/utils/submission';
import { getPeriod } from '@common/utils/submission-period';
import { submissionStatusValueProvider, submissionTypeValueProvider } from '@common/value-provider';
import { setAppIsLoading } from '@modules/app/redux/action';
import FilesListField from '@modules/shared/components/Form/FilesListField';
import FormPrint from '@modules/shared/components/FormPrint';
import SidePanel from '@modules/shared/components/SidePanel';
import Tooltip from '@modules/shared/components/Tooltip';
import {
  getAvailableStatuses,
  getAvailableSubmissionTypes,
  getModificationsCount,
  getSubmission,
} from '../../service';
import RevisionSection from '../RevisionSection';
import ActivityClaimSection from './../ActivityClaimSection';
import ActivitySubmissionSection from './../ActivitySubmissionSection';
import CoursesClaimSection from './../CoursesClaimSection';
import CoursesSubmissionSection from './../CoursesSubmissionSection';
import PaymentEdition from './../PaymentEdition';
import PaymentInfo from './../PaymentInfo';
import SubmissionGeneralInformationSection from './../SubmissionGeneralInformationSection';

interface IProps {
  submission: ISubmission;
  submitHandler: (
    values: any,
    shouldNotifyMember: boolean,
    setErrors?: (errors: IFormErrors) => void,
  ) => Promise<void>;
  cancelHandler: () => void;
}

enum TabType {
  GeneralInfos = 'infos',
  Subvention = 'subvention',
  Claim = 'claim',
  Revision = 'revision',
  Payment = 'payment',
  Notes = 'note',
}

const defaultNotifyMemberValue = true;

function SubmissionEditionPanel({ submission, submitHandler, cancelHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formikRef = useRef<ReturnType<typeof useFormik>>(null);
  const validationSchemaRef = useRef<Yup.ObjectSchema<any>>(null);

  const [initialValues, setInitialValues] = useState<ISubmission>(null);
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>(null);
  const [submissionType, setSubmissionType] = useState<SubmissionType>(null);
  const [isPaymentModified, setIsPaymentModified] = useState<boolean>(false);
  const [isSubmissionLocked, setSubmissionLocked] = useState<boolean>(null);

  useEffect(() => {
    dispatch(setAppIsLoading(true));
    getSubmission(submission.type, submission.id)
      .then((submission: ISubmission) => {
        validationSchemaRef.current = getEditionValidationSchemaFromType(Yup, submission.type, submission);

        setSubmissionStatus(submission.status);
        setSubmissionType(submission.type);
        setInitialValues(submission);
        setSubmissionLocked(submission.locked);

        const values = {
          ...initialValues,
          ...formikRef.current.values,
        };
        formikRef.current.setValues(values, true);
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('submissionAdminPage.error'));
      })
      .finally(() => dispatch(setAppIsLoading(false)));
  }, []);

  const onSubmit = (
    { shouldNotifyMember, ...submission }: ISubmission & { shouldNotifyMember: boolean },
    setErrors?: (errors: IFormErrors) => void,
  ): Promise<void> => {
    if (
      (submission.status === SubmissionStatus.ClaimRefused &&
        submission.status !== initialValues.status &&
        !confirm(t('submissionAdminPage.statusChangeAmountSetTo0'))) ||
      (!submission.locked && initialValues.locked && !confirm(t('submissionAdminPage.lockSubmissionConfirm')))
    ) {
      return Promise.resolve();
    }

    const willNotifyMember =
      (isPaymentModified || submission.status != initialValues.status) && shouldNotifyMember;

    return submitHandler({ ...submission, locked: isSubmissionLocked }, willNotifyMember, setErrors).then(
      () => {
        if (willNotifyMember) toast.success(t('submissionAdminPage.notifyMemberSuccess'));
      },
    );
  };

  const submissionTypeValueList: ISelectFieldOption[] = getAvailableSubmissionTypes(submission.type).map(
    (value) => ({
      value,
      label: `${submissionTypeValueProvider.label(value)} (${value})`,
    }),
  );

  const handleSubmissionTypeChanged = (type: SubmissionType): void => {
    const version = getVersion(type, getPeriod(submission.periodId));

    formikRef.current.setFieldValue('version', version);
    validationSchemaRef.current = getEditionValidationSchemaFromType(Yup, type, { ...submission, version });

    setSubmissionType(type);
  };

  const onPaymentChange = (payment: IPayment) => {
    setIsPaymentModified(payment !== initialValues.claim?.payment);
  };

  const onLockChange = () => {
    const newLockValue = !isSubmissionLocked;
    setSubmissionLocked(newLockValue);
    formikRef.current.setFieldValue('locked', newLockValue);
  };

  const renderGeneralInformation = () => {
    return (
      <>
        <FormFields title={t('submissionAdminPage.generalInfo')}>
          <FormSelectField
            name="type"
            label={t('submissionPage.submissionType')}
            disabled={submissionTypeValueList.length <= 1}
            onChange={(newType) => handleSubmissionTypeChanged(newType as SubmissionType)}
          >
            <FormSelectFieldOptions
              options={submissionTypeValueList}
              keepOriginalOrder
              addEmptyOption={false}
            />
          </FormSelectField>
          <FormSelectField name="language" label={t('submission.language')}>
            <FormSelectFieldOptions
              addEmptyOption={false}
              options={Object.values(Language).map((langValue) => ({
                value: langValue,
                label: i18n.t('enum.language', { context: langValue }),
              }))}
            />
          </FormSelectField>
          {[
            SubmissionStatus.ClaimRefused,
            SubmissionStatus.SubmissionRefused,
            SubmissionStatus.SubmissionCancelled,
            SubmissionStatus.SubmissionNotRealised,
          ].includes(submissionStatus) && (
            <FormTextAreaField name="justification" label={i18n.t('claim.justification')} width={100} />
          )}
          {[
            SubmissionStatus.ClaimWaitingForDocuments,
            SubmissionStatus.SubmissionWaitingForDocuments,
          ].includes(submissionStatus) && (
            <FormTextAreaField
              name="missingDocumentsDescription"
              label={i18n.t('claim.missingDocumentsDescription')}
              width={100}
            />
          )}
        </FormFields>

        <SubmissionGeneralInformationSection editMode />
      </>
    );
  };

  const renderSubmissionForm = (type: SubmissionType) => {
    return (
      <>
        {(type === SubmissionType.Cu || type === SubmissionType.If) && (
          <CoursesSubmissionSection submissionType={type} editMode />
        )}
        {(type === SubmissionType.Pca || type === SubmissionType.Pp) && (
          <ActivitySubmissionSection submissionType={type} editMode />
        )}
        <FormFields title={t('submissionPage.message')}>
          <FormTextAreaField name="message" width={100} />
        </FormFields>
      </>
    );
  };

  const renderClaimForm = (type: SubmissionType) => {
    return (
      <>
        {(type === SubmissionType.Cu || type === SubmissionType.If) && (
          <CoursesClaimSection submissionType={type} editMode />
        )}
        {(type === SubmissionType.Pca || type === SubmissionType.Pp) && (
          <ActivityClaimSection submissionType={type} editMode />
        )}
        <FormFields title={t('claimPage.supportingDocumentsSection')} required>
          <div className="cpdFormSingleField">
            <FilesListField name="claim.supportingDocuments" width={100} />
          </div>
        </FormFields>
        <FormFields title={t('submissionPage.message')}>
          <FormTextAreaField name="claim.message" width={100} />
        </FormFields>
      </>
    );
  };

  const renderPaymentForm = () => {
    return (
      <>
        <PaymentInfo readonly={initialValues.status === SubmissionStatus.ClaimPaid} editMode />
        {[SubmissionStatus.ClaimAccepted, SubmissionStatus.ClaimPaid].includes(initialValues.status) && (
          <PaymentEdition onPaymentChange={onPaymentChange} submission={initialValues} />
        )}
        <div className="cpdAdditionalNotes">
          <FormTextAreaField name="additionalNotes" label={t('submissionAdminPage.notes')} width={100} />
        </div>
      </>
    );
  };

  if (!initialValues) {
    return null;
  }

  const title = `${submission.code} – ${submission.firstName} ${submission.lastName}`;
  const modificationsCount = getModificationsCount(formikRef.current?.values as ISubmission);
  const selectedStatusList: ISelectFieldOption[] = getAvailableStatuses(
    initialValues.status,
    initialValues.type,
  ).map((value) => ({
    value,
    label: submissionStatusValueProvider.label(value),
  }));

  return (
    <FormContext
      initialValues={{
        ...initialValues,
        shouldNotifyMember: defaultNotifyMemberValue,
        isSubmissionLocked,
      }}
      validationSchema={validationSchemaRef.current}
      onSubmit={onSubmit}
      formikRef={formikRef}
    >
      <FormPrint title={initialValues.code}>
        <SidePanel closeHandler={cancelHandler}>
          <TabContext>
            <SidePanelHeader displayCloseButton>
              <div className="cpdSidePanel__header__content">
                {title}

                <div className="cpdToolbar" role="toolbar">
                  <FormSelectField
                    name="status"
                    width={100}
                    onChange={(newStatus) => setSubmissionStatus(newStatus as SubmissionStatus)}
                    disabled={initialValues.status === SubmissionStatus.ClaimPaid}
                  >
                    <FormSelectFieldOptions
                      options={selectedStatusList}
                      keepOriginalOrder
                      addEmptyOption={false}
                    />
                  </FormSelectField>

                  <Tooltip
                    text={t('submissionAdminPage.actions.lock', {
                      context: !isSubmissionLocked ? 'unlocked' : '',
                    })}
                  >
                    <Button size={ButtonSize.Small} style={ButtonStyle.Action} onClick={() => onLockChange()}>
                      <span
                        className={`material-icons cpdIcon cpdIcon--${
                          isSubmissionLocked ? 'locked' : 'unlocked'
                        }`}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip text={t('submissionAdminPage.actions.mail')}>
                    <a
                      className="novoButton novoButton--action novoButton--small"
                      href={`mailto:${submission.email}?subject=${submission.code}`}
                    >
                      <span className={`material-icons cpdIcon cpdIcon--contact`} />
                    </a>
                  </Tooltip>

                  <Tooltip text={t('submissionAdminPage.actions.print')}>
                    <Button size={ButtonSize.Small} style={ButtonStyle.Action} onClick={() => window.print()}>
                      <span className={`material-icons cpdIcon cpdIcon--print`} />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </SidePanelHeader>

            <Form>
              <SidePanelHeaderNav isHost>
                <TabList>
                  <Tab value={TabType.GeneralInfos} active>
                    {t('submissionAdminPage.generalInfo')}
                  </Tab>
                  <Tab value={TabType.Subvention}>{t('submissionAdminPage.subventionRequest')}</Tab>
                  {initialValues.claim && (
                    <>
                      <Tab value={TabType.Claim}>{t('submissionAdminPage.claimRequest')}</Tab>
                      {submissionStatus !== SubmissionStatus.ClaimRefused && (
                        <>
                          <Tab value={TabType.Revision}>
                            {`${t('submissionAdminPage.revision')} ${
                              modificationsCount > 0 ? `(${modificationsCount})` : ''
                            }`}
                          </Tab>
                          <Tab value={TabType.Payment}>{t('submissionAdminPage.payment')}</Tab>
                        </>
                      )}
                    </>
                  )}
                  <Tab value={TabType.Notes}>{t('submissionAdminPage.notes')}</Tab>
                </TabList>
              </SidePanelHeaderNav>
              <SidePanelContent>
                <TabPanel value={TabType.GeneralInfos}>{renderGeneralInformation()}</TabPanel>
                <TabPanel value={TabType.Subvention}>{renderSubmissionForm(submissionType)}</TabPanel>
                {initialValues.claim && (
                  <>
                    <TabPanel value={TabType.Claim}>{renderClaimForm(submissionType)}</TabPanel>
                    {submissionStatus !== SubmissionStatus.ClaimRefused && (
                      <>
                        <TabPanel value={TabType.Revision}>
                          <RevisionSection />
                        </TabPanel>
                        <TabPanel value={TabType.Payment}>{renderPaymentForm()}</TabPanel>
                      </>
                    )}
                  </>
                )}
                <TabPanel value={TabType.Notes}>
                  <FormTextAreaField name="notes" width={100} rows={10} />
                </TabPanel>
              </SidePanelContent>

              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small>
                    {t('general.forms.save')}
                  </FormActionButton>
                  {(isPaymentModified || submissionStatus !== initialValues.status) && (
                    <div className="cpdFormSendEmailCheckbox">
                      <FormCheckboxField
                        name="shouldNotifyMember"
                        label={t('submissionAdminPage.notifyMemberOption')}
                        width={100}
                      />
                    </div>
                  )}
                </SidePanelFooterActions>
                <SidePanelFooterActions isSecondary>
                  <FormActionButton type={FormActionButtonType.Reset} small />
                </SidePanelFooterActions>
              </SidePanelFooter>
            </Form>
          </TabContext>
        </SidePanel>
      </FormPrint>
    </FormContext>
  );
}

export default SubmissionEditionPanel;
